import './Sidebar.scss';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { errorAppStates } from '../../../constants';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { AppCuesIds } from '../../../external-references';
import { trackEvent } from '../../../extra/sharedMethods';
import { useAppConnectionStatus } from '../../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import {
  setSidebarAdminOpen,
  setSidebarKnowledgeOpen,
  setSidebarPersonalOpen,
  setSidebarSettingsOpen,
} from '../../../redux/sidebar/actions';
import { useDispatch } from '../../../redux/store';
import { logout } from '../../../scripts/authentication';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  useBoolState,
  useFlag,
  useGlobalState,
  useHistoryPush,
  useOrgPreference,
  useSidebarOpen,
  useUserSafe,
} from '../../../scripts/hooks';
import { useExtensionObserver } from '../../../scripts/hooks/extension';
import { toggleSidebar } from '../../../scripts/sidebar';
import {
  extensionEnabledBrowser,
  installExtension,
  isChrome,
} from '../../../scripts/utils';
import { CompanyLogo } from '../../controls/CompanyLogo/CompanyLogo';
import { InstallChromeExtensionDialog } from '../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { ProductFeedbackDialog } from '../../controls/ProductFeedbackDialog/ProductFeedbackDialog';
import { BetaBadge } from '../../controls/ui/BetaBadge/BetaBadge';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon, UIIcons } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';
import { MembersTableDialog } from '../MembersTableDialog/MembersTableDialog';
import { TopicsList } from './TopicsList';

const trackSidebarItemClick = (title: string) => {
  trackEvent(AnalyticsEvent.SidebarItemClick, {
    title,
  });
};

interface SidebarHeaderProps {
  topicsOpen: boolean;
  setTopicsOpen: (open: boolean) => void;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({
  topicsOpen,
  setTopicsOpen,
}: SidebarHeaderProps) => {
  const user = useUserSafe();
  const companyName = user.orgByOrgId.name;

  const onCollapseClick = useCallback(() => {
    toggleSidebar(false);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="companyHeader">
        <Link to="/">
          <div className="headerLeft">
            <CompanyLogo />
            <span className="companyName">{companyName || 'Dashworks'}</span>
          </div>
        </Link>
        <UIIconButton name="arrow-left" onClick={onCollapseClick} size={20} />
      </div>
      <div className="mt-2 px-2 flex flex-col gap-0.5">
        <NavLink
          className="sidebarLink flex text-black items-center rounded justify-between"
          exact
          to={SidebarRoutes.JitQA}
        >
          <div className="flex items-center gap-1.5">
            <UIIcon name="messages" size={20} />
            Dash AI
          </div>
          <UIIconButton
            name={topicsOpen ? 'arrow-up' : 'arrow-down'}
            onClick={() => {
              setTopicsOpen(!topicsOpen);
            }}
            size={20}
          />
        </NavLink>
      </div>
    </div>
  );
};

export const SidebarLink: FC<{
  error?: boolean;
  to: string;
  icon?: UIIcons;
  iconStroke?: boolean;
  title: string;
  logName: string;
  beta?: boolean;
  external?: boolean;
  appCuesId?: AppCuesIds;
  textColor?: string;
}> = ({
  error,
  to,
  icon,
  iconStroke,
  title,
  logName,
  beta,
  external,
  appCuesId,
  textColor = 'text-black',
}) => {
  const onClick = useCallback(() => {
    trackSidebarItemClick(logName);
  }, [logName]);

  const content = (
    <>
      <div className="flex items-center gap-1.5">
        {icon && (
          <UIIcon
            className="sidebarLinkIcon"
            name={icon}
            size={20}
            stroke={iconStroke}
          />
        )}
        {title}
        {beta && <BetaBadge />}
      </div>
      {error && (
        <div className="text-mahogany-20">
          <ErrorOutlinedIcon fontSize="small" />{' '}
        </div>
      )}
    </>
  );

  return external ? (
    <a
      className={`sidebarLink flex items-center rounded ${textColor}`}
      href={to}
      onClick={onClick}
      rel="noreferrer"
      target="_blank"
    >
      {content}
    </a>
  ) : (
    <NavLink
      className={`sidebarLink flex items-center justify-between rounded ${textColor}`}
      data-appcues-id={appCuesId}
      exact
      onClick={onClick}
      to={to}
    >
      {content}
    </NavLink>
  );
};

interface SidebarPopoverButtonProps {
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  logName: string;
  id?: string;
  href?: string;
}

const SidebarPopoverButton: FC<SidebarPopoverButtonProps> = ({
  children,
  onClick,
  id,
  logName,
  href,
}) => {
  const onButtonClick = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      trackSidebarItemClick(logName);
      if (onClick) onClick(event);
    },
    [onClick, logName]
  );

  if (href) {
    return (
      <a
        className="flex items-center gap-2 p-2 text-black hover:text-black hover:bg-cloud-15 rounded cursor-pointer font-medium"
        href={href}
        id={id}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  return (
    <div
      className="flex items-center gap-2 p-2 hover:bg-cloud-15 rounded cursor-pointer font-medium"
      id={id}
      onClick={onButtonClick}
    >
      {children}
    </div>
  );
};

interface SidebarButtonProps {
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  logName: string;
  className?: string;
  id?: string;
  propagate?: boolean;
}

const SidebarButton: FC<SidebarButtonProps> = ({
  children,
  onClick,
  logName,
  className,
  id,
  propagate,
}) => {
  const onButtonClick = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      trackSidebarItemClick(logName);
      if (onClick) onClick(event);
    },
    [onClick, logName]
  );

  return (
    <UIButton
      className={classNames('sidebarButton !text-black', className ?? '')}
      id={id}
      onClick={onButtonClick}
      propagate={propagate}
      size="small"
      type="flat"
    >
      {children}
    </UIButton>
  );
};

const SectionHeader: FC<{
  onToggleExpand?: () => void;
  onClick?: () => void;
  iconName: UIIcons;
  title: string;
  logName: string;
  active: boolean;
}> = ({ onToggleExpand, iconName, onClick, title, logName, active }) => {
  return (
    <div className="sectionHeader">
      <SidebarButton
        logName={`header-${logName}`}
        onClick={onToggleExpand ?? onClick}
      >
        <UIIcon className="!mr-1.5 shrink-0" name={iconName} size={20} />
        <span className={classNames({ 'text-black': active })}>{title}</span>
        {onToggleExpand && (
          <UIIcon className="expandIcon" name="arrow-down" size={16} />
        )}
      </SidebarButton>
    </div>
  );
};

const SidebarFooter: FC = () => {
  const dispatch = useDispatch();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const [openMembersDialog, setOpenMembersDialog, setClosedMembersDialog] =
    useBoolState(false);

  const [
    openProductFeedbackDialog,
    setOpenProductFeedbackDialog,
    setClosedProductFeedbackDialog,
  ] = useBoolState(false);

  const [showSupportAndResources, setShowSupportAndResources] = useState(false);

  const isEarn500Enabled = useFlag('earn500SidebarLink');

  const extensionInstalled = useExtensionObserver();

  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const openSettingsSidebar = useCallback(() => {
    dispatch(setSidebarSettingsOpen(!sidebarSettingsOpen));
  }, [dispatch, sidebarSettingsOpen]);

  const openSettingsPage = useHistoryPush(SidebarRoutes.AppsV2);

  const onSettingsClick = useCallback(() => {
    openSettingsSidebar();
    openSettingsPage();
  }, [openSettingsSidebar, openSettingsPage]);

  return (
    <>
      <div className="sidebarFooter">
        <div className="flex flex-col gap-0.5 px-2 border-solid border-0 border-t border-cloud-15">
          <SidebarButton logName="settings" onClick={onSettingsClick}>
            <UIIcon className="sidebarLinkIcon" name="cog" size={20} />
            Settings
          </SidebarButton>
          <SidebarButton logName="invite" onClick={setOpenMembersDialog}>
            <UIIcon className="sidebarLinkIcon" name="invite" size={20} />
            Invite
          </SidebarButton>
          {extensionEnabledBrowser && !extensionInstalled && (
            <SidebarButton
              logName="extension"
              onClick={
                isChrome
                  ? setOpenInstallChromeExtensionDialog
                  : installExtension
              }
            >
              <UIIcon
                className="mr-2"
                name={isChrome ? 'chrome' : 'firefox'}
                type="apps"
              />
              Install Extension
            </SidebarButton>
          )}
          <Popover
            onOpenChange={setShowSupportAndResources}
            open={showSupportAndResources}
          >
            <PopoverTrigger asChild>
              <div
                className="flex items-center gap-2 p-2 hover:bg-cloud-15 rounded cursor-pointer font-medium"
                onClick={() => {
                  setShowSupportAndResources(!showSupportAndResources);
                }}
              >
                <UIIcon name="help" size={20} />
                <span className="text-black">Support</span>
              </div>
            </PopoverTrigger>
            <PopoverContent className="z-[1000] bg-white text-sm p-1 w-[228px]">
              <SidebarPopoverButton
                logName="support"
                onClick={() => {
                  // eslint-disable-next-line new-cap
                  Pylon('show');
                }}
              >
                <UIIcon name="message" size={20} />
                <span>Contact Us</span>
              </SidebarPopoverButton>

              <SidebarPopoverButton
                href="https://support.dashworks.ai"
                logName="help-center"
              >
                <UIIcon name="glossary" size={20} />
                <span>Visit Help Center</span>
              </SidebarPopoverButton>
              <SidebarPopoverButton
                logName="feedback"
                onClick={setOpenProductFeedbackDialog}
              >
                <UIIcon name="light-bulb" size={20} />
                <span>Request Feature</span>
              </SidebarPopoverButton>
              {isEarn500Enabled && (
                <SidebarPopoverButton
                  href="https://support.dashworks.ai/referral-program"
                  logName="earn500"
                >
                  <UIIcon name="gift" size={20} />
                  <span>Earn $500</span>
                </SidebarPopoverButton>
              )}
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <MembersTableDialog
        onClose={setClosedMembersDialog}
        open={openMembersDialog}
      />
      <ProductFeedbackDialog
        onClose={setClosedProductFeedbackDialog}
        open={openProductFeedbackDialog}
      />
      <InstallChromeExtensionDialog
        onClose={setNotOpenInstallChromeExtensionDialog}
        open={openInstallChromeExtensionDialog}
      />
    </>
  );
};

const SettingsLinks: FC = () => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const onClick = useCallback(() => {
    dispatch(setSidebarSettingsOpen(!sidebarSettingsOpen));
  }, [dispatch, sidebarSettingsOpen]);

  return (
    <div className="settingsSection">
      <div className="settingsHeader">
        <UIIconButton link="/" name="arrow-left" onClick={onClick} size={24} />
        <NavLink className="settings" onClick={onClick} to="/">
          Settings
        </NavLink>
      </div>
      <KnowledgeLinks />
      <div className="flex flex-col gap-0.5 px-2 border-solid border-0 border-t border-cloud-15">
        <SidebarLink
          icon="bot"
          logName="bots"
          title="Bots"
          to={SidebarRoutes.Bots}
        />
        <SidebarLink
          icon="bolt"
          logName="workflows"
          title="Workflows"
          to={SidebarRoutes.Workflows}
        />
      </div>
      {user.admin && <WorkspaceLinks />}
      <PersonalLinks />
    </div>
  );
};

const KnowledgeLinks: FC = () => {
  const dispatch = useDispatch();
  const sidebarKnowledgeOpen = useGlobalState(
    (state) => state.sidebar.knowledgeOpen
  );

  const { isAdmin } = useUserSafe((u) => ({
    isAdmin: u.admin,
  }));

  const answersEnabled = useOrgPreference('answers', true);
  const isFileUploadEnabled = useFlag('fileUploadsEnabled');

  const userApps = useAppConnectionStatus();
  const isAppInErrorState = useMemo(() => {
    // Checks if any app is in an error state.
    return userApps.some((app) => {
      if (isAdmin) {
        return errorAppStates.has(app.statusCode) && app.isInstant;
      }

      return errorAppStates.has(app.statusCode) && app.isInstant && !app.isOrg;
    });
  }, [isAdmin, userApps]);

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarKnowledgeOpen(!sidebarKnowledgeOpen));
  }, [dispatch, sidebarKnowledgeOpen]);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarKnowledgeOpen}
        iconName="glossary"
        logName="knowledge"
        onToggleExpand={onToggleExpand}
        title="Knowledge"
      />
      <div
        className={classNames('linksChildren', {
          hidden: !sidebarKnowledgeOpen,
        })}
      >
        <SidebarLink
          error={isAppInErrorState}
          icon="app"
          iconStroke
          logName="appStore"
          title="Apps"
          to={SidebarRoutes.AppsV2}
        />
        {isFileUploadEnabled && isAdmin && (
          <SidebarLink
            icon="doc"
            logName="files"
            title="Files"
            to={SidebarRoutes.Files}
          />
        )}
        {answersEnabled && (
          <SidebarLink
            icon="answer"
            logName="answers"
            title="Answers"
            to="/answers"
          />
        )}
      </div>
    </div>
  );
};

const WorkspaceLinks: FC = () => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const sidebarAdminOpen = useGlobalState((state) => state.sidebar.adminOpen);
  const analytics = useFlag('adminAnalytics');
  const showAnalytics = analytics && user.orgByOrgId.exploToken?.length;
  const apiKeysEnabled = useFlag('apiKeysEnabled');

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarAdminOpen(!sidebarAdminOpen));
  }, [dispatch, sidebarAdminOpen]);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarAdminOpen}
        iconName="company"
        logName="workspace"
        onToggleExpand={onToggleExpand}
        title="Workspace"
      />
      <div
        className={classNames('linksChildren', { hidden: !sidebarAdminOpen })}
      >
        <SidebarLink
          icon="design"
          logName="workspace-general"
          title="General"
          to={SidebarRoutes.Appearance}
        />
        <SidebarLink
          icon="people"
          logName="workspace-members"
          title="Members"
          to="/admin/members"
        />
        <SidebarLink
          icon="bot"
          logName="workspace-serviceAccount"
          title="Bot Account"
          to={SidebarRoutes.ServiceAccount}
        />

        <SidebarLink
          icon="sync"
          logName="directory-sync"
          title="Directory Sync"
          to={SidebarRoutes.DirectorySync}
        />
        {showAnalytics && (
          <SidebarLink
            icon="activity"
            logName="workspace-analytics"
            title="Analytics"
            to={SidebarRoutes.Analytics}
          />
        )}
        <SidebarLink
          icon="lock"
          logName="workspace-security"
          title="Security"
          to={SidebarRoutes.SSO}
        />
        {apiKeysEnabled && (
          <SidebarLink
            icon="key"
            logName="workspace-api-keys"
            title="API Keys"
            to={SidebarRoutes.APIKeys}
          />
        )}
        <SidebarLink
          icon="update"
          logName="workspace-billing"
          title="Billing"
          to={SidebarRoutes.Billing}
        />
        <SidebarLink
          icon="settings-2"
          logName="workspace-ai-controls"
          title="AI Controls"
          to={SidebarRoutes.AiControls}
        />
      </div>
    </div>
  );
};

const PersonalLinks: FC = () => {
  const dispatch = useDispatch();
  const sidebarPersonalOpen = useGlobalState(
    (state) => state.sidebar.personalOpen
  );

  const userEmail = useUserSafe((u) => u.email);
  const supportMultipleLlmProviders = useFlag('supportMultipleLlmProviders');

  const onToggleExpand = useCallback(() => {
    dispatch(setSidebarPersonalOpen(!sidebarPersonalOpen));
  }, [dispatch, sidebarPersonalOpen]);

  const handleLogout = useCallback(() => {
    trackEvent(AnalyticsEvent.LogoutClick);
    void logout();
  }, []);

  return (
    <div className="linksSection">
      <SectionHeader
        active={sidebarPersonalOpen}
        iconName="person"
        logName="personal"
        onToggleExpand={onToggleExpand}
        title="Personal"
      />
      <div
        className={classNames('linksChildren', {
          hidden: !sidebarPersonalOpen,
        })}
      >
        <SidebarLink
          appCuesId={AppCuesIds.MySettingsLink}
          icon="design"
          logName="personal-general"
          title="General"
          to={SidebarRoutes.MySettings}
        />
        <SidebarLink
          icon="extension"
          logName="browser-extension"
          title="Browser Extension"
          to={SidebarRoutes.BrowserExtension}
        />
        {supportMultipleLlmProviders && (
          <SidebarLink
            icon="settings-2"
            logName="personal-ai-controls"
            title="AI Controls"
            to={SidebarRoutes.MyAiControls}
          />
        )}
        <SidebarButton logName="logout" onClick={handleLogout}>
          <Tooltip title={userEmail}>
            <Box>
              <UIIcon className="sidebarLinkIcon" name="log-out" />
              Logout
            </Box>
          </Tooltip>
        </SidebarButton>
      </div>
    </div>
  );
};

export const Sidebar: FC = () => {
  const sidebarOpen = useSidebarOpen();
  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const [topicsOpen, setTopicsOpen] = useState(true);

  return (
    <div
      className={classNames(
        'sidebar h-screen overflow-y-auto bg-cloud-10 text-sm',
        {
          open: sidebarOpen,
        }
      )}
    >
      {sidebarSettingsOpen ? (
        <SettingsLinks />
      ) : (
        <>
          <SidebarHeader
            setTopicsOpen={setTopicsOpen}
            topicsOpen={topicsOpen}
          />
          <TopicsList topicsOpen={topicsOpen} />
          <SidebarFooter />
        </>
      )}
    </div>
  );
};
