import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import { QAStages } from '../../../models/QAmodels';
import { Sources, SupportedLlm } from '../../../models/User';
import { WorkflowModalTypes } from '../../../models/Workflows';
import {
  MAX_QUERY_LENGTH,
  QAControllerEventArgs,
  useQAController,
} from '../../../scripts/QAController';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  QueryParams,
  useFlag,
  useToaster,
  useUserSafe,
} from '../../../scripts/hooks';
import { useBots } from '../../../scripts/hooks/bots';
import { getSourcesFromFilters } from '../../../scripts/sources/common';
import { logError } from '../../../scripts/utils';
import { LLMSelectPopoverBtn } from '../../LLMSelect/LLMSelectPopoverBtn';
import { SourcesFilterSwitch } from '../../SourcesFilterSwitch/SourcesFilterSwitch';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import { BotsSelectButtonDashAi } from '../../general/botsSelect/BotsSelectDashAi';
import { QAMentionsInput } from '../QAMentionsInput';
import { useQATextInputBoxStates } from './QATextInputBoxStates';

export interface SendQueryArgs {
  queryText: string;
  conversation_id?: string;
  sources?: Sources;
  llm_preference: SupportedLlm | null;
  continueTopic?: boolean;
  bot_id?: string;
}

export interface QATextInputProps {
  className?: string;
  autofocus?: boolean;
  hideNewTopicButton?: boolean;
  hideStopAnswerGenerationButton?: boolean;
  showPopupFromBottom?: boolean;
  continueConversationId?: string;
  setOpenPaywallModal(): void;
  sendQueryCallback?(): void;
}

// eslint-disable-next-line max-lines-per-function
export const QATextInputBox: FC<QATextInputProps> = ({
  className = '',
  autofocus = true,
  hideNewTopicButton = false,
  hideStopAnswerGenerationButton = false,
  showPopupFromBottom = false,
  continueConversationId,
  setOpenPaywallModal,
  sendQueryCallback,
}: QATextInputProps) => {
  const stopGenerationFlag = useFlag('stopAnswerGeneration');
  const supportMultipleLlmProviders = useFlag('supportMultipleLlmProviders');

  const history = useHistory();

  const toaster = useToaster();
  const userEmail = useUserSafe((u) => u.email);

  const [queryParams] = useQueryParams({
    [QueryParams.Search]: StringParam,
  });

  const isLoaded = useRef(false);

  const {
    dynamicPlaceholder,
    searchQuery,
    mentions,
    skillFilters,
    fileFilters,
    answerFilters,
    isBaseLLM,
    selectedLlm,
    setDynamicPlaceholder,
    setSearchQuery,
    setMentions,
    setSkillFilters,
    setFileFilters,
    setAnswerFilters,
    setIsBaseLLM,
    setSelectedLlm,
    allSkills,
    allSkillsUserApps,
    allFiles,
    allAnswers,
    inputRef,
  } = useQATextInputBoxStates();

  const { bots, botsEnabledApps, botsEnabledUserApps } = useBots();
  const qaController = useQAController();

  const { currentStage } = qaController.useProgressStage();
  const { isNewTopic } = qaController.getIsNewTopic();
  const messages = qaController.useMessages();
  const conversation_id = messages[messages.length - 1]?.conversation_id;

  const placeholderText = useMemo(() => {
    if (isBaseLLM) {
      return 'Ask AI without searching your Apps or Web...';
    }

    if (skillFilters.length === 1 && skillFilters[0]?.appName === 'web') {
      return 'Ask to find answers from the Web...';
    }

    return 'Ask to find answers from your Apps...';
  }, [isBaseLLM, skillFilters]);

  const sendQuery = useCallback(
    (query: string) => {
      if (query === '') {
        return;
      }

      if (currentStage !== QAStages.WAIT_USER_INPUT) {
        trackEvent(AnalyticsEvent.QAUserInputWhilePreviousResponseLoading);
        toaster.failure(
          'Please hold on while we generate an answer for your previous query.'
        );

        return;
      }

      if (
        !mentions &&
        !isBaseLLM &&
        skillFilters.length === 0 &&
        fileFilters.length === 0 &&
        answerFilters.length === 0
      ) {
        toaster.failure(
          'Please select some sources from the Apps tab and try again.'
        );

        return;
      }

      if (sendQueryCallback) {
        sendQueryCallback();
      }

      let bot_id: string | undefined;
      let sources: Sources | undefined;

      if (mentions) {
        const bot = bots.find((b) => b.id === mentions);
        if (bot) {
          bot_id = mentions;
        }
      }

      if (!bot_id) {
        sources = getSourcesFromFilters(
          isBaseLLM,
          skillFilters,
          fileFilters,
          answerFilters,
          allSkills,
          allFiles,
          allAnswers
        );
      }

      const args: SendQueryArgs = {
        queryText: query,
        sources,
        llm_preference:
          (isBaseLLM && supportMultipleLlmProviders) || !isBaseLLM
            ? selectedLlm
            : SupportedLlm.OPENAI_GPT4_OMNI,
        continueTopic: !!continueConversationId,
        bot_id,
      };

      if (!isNewTopic) {
        args.conversation_id = conversation_id;
      }

      if (continueConversationId) {
        args.conversation_id = continueConversationId;
      }

      qaController.sendNewMessage(args).catch((error) => {
        logError(error);
        toaster.failure('Error processing request. Please try again.');
      });

      trackEvent(
        AnalyticsEvent.QAUserAskedQuestion,
        {
          user: userEmail,
          timestamp: new Date(),
          searchFilters: bot_id
            ? null
            : skillFilters.map(({ id, appName, displayName, isOrg }) => ({
                id,
                appName,
                displayName,
                isOrg,
              })),
          fileFilters: bot_id ? null : fileFilters.map(({ id }) => id),
          answerFilters: bot_id ? null : answerFilters.map(({ id }) => id),
          bot_id: bot_id ?? null,
        },
        {
          question: query,
        }
      );

      setMentions(undefined);
      qaController.setIsNewTopic(false);
      setSearchQuery('');
    },
    [
      currentStage,
      mentions,
      isBaseLLM,
      skillFilters,
      fileFilters,
      answerFilters,
      sendQueryCallback,
      supportMultipleLlmProviders,
      selectedLlm,
      continueConversationId,
      isNewTopic,
      qaController,
      userEmail,
      setMentions,
      setSearchQuery,
      toaster,
      bots,
      allSkills,
      allFiles,
      allAnswers,
      conversation_id,
    ]
  );

  // TODO: Move the setSearchQuery logic to UITextArea
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent) => {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        if (evt.ctrlKey || evt.metaKey || evt.shiftKey || evt.altKey) {
          const target = evt.currentTarget as HTMLTextAreaElement;
          const cursorPosition = target.selectionStart;

          const textBeforeCursor = searchQuery.slice(
            0,
            Math.max(0, cursorPosition)
          );

          const textAfterCursor = searchQuery.slice(
            Math.max(0, cursorPosition)
          );

          setSearchQuery(`${textBeforeCursor}\n${textAfterCursor}`);

          setTimeout(() => {
            target.selectionStart = cursorPosition + 1;
            target.selectionEnd = cursorPosition + 1;
            target.focus();
          }, 0);
        } else {
          sendQuery(searchQuery);
        }
      }
    },
    [searchQuery, sendQuery, setSearchQuery]
  );

  const handleNewTopicButton = useCallback(() => {
    if (continueConversationId) {
      qaController.setIsNewTopic(true);
      trackEvent(AnalyticsEvent.QANewTopicToggled, { user: userEmail });
      history.push(SidebarRoutes.JitQA);
    } else {
      qaController.setIsNewTopic(!isNewTopic);
      trackEvent(AnalyticsEvent.QANewTopicToggled, { user: userEmail });
    }
  }, [continueConversationId, history, isNewTopic, userEmail, qaController]);

  const handleTextInputChange = useCallback(
    (newValue: string) => {
      // fix for the bug where the text area has new line
      if (searchQuery === '' && newValue === '\n') {
        setSearchQuery('');
      } else {
        setSearchQuery(newValue);
      }
    },
    [searchQuery, setSearchQuery]
  );

  const handleSaveWorkflowTemplate = useCallback(
    ({ workflowTemplate }: Partial<QAControllerEventArgs>) => {
      if (workflowTemplate) {
        trackEvent(AnalyticsEvent.ClickedOnSaveWorkflowButton, {
          promptTemplate: workflowTemplate,
          sources: getSourcesFromFilters(
            isBaseLLM,
            skillFilters,
            fileFilters,
            answerFilters,
            allSkills,
            allFiles,
            allAnswers
          ),
          preferredLlm: selectedLlm,
        });

        qaController.triggerEvent('showWorkflowModal', {
          workflowModalType: WorkflowModalTypes.CREATE,
          workflowTemplate,
          isBaseLLM,
          skillFilters,
          fileFilters,
          answerFilters,
          preferredLlm: selectedLlm,
        });
      }
    },
    [
      allAnswers,
      allFiles,
      allSkills,
      answerFilters,
      fileFilters,
      isBaseLLM,
      qaController,
      selectedLlm,
      skillFilters,
    ]
  );

  const handleViewTemplatesButton = useCallback(() => {
    trackEvent(AnalyticsEvent.OpenWorkflowsModalFromDashAi);

    qaController.triggerEvent('showWorkflowModal', {
      workflowModalType: WorkflowModalTypes.VIEW_ALL_TEMPLATES,
    });
  }, [qaController]);

  const handleStopGeneration = useCallback(() => {
    qaController.triggerEvent('stopGenerating', {});
  }, [qaController]);

  const handleSetQuery = useCallback(
    ({ query }: Partial<QAControllerEventArgs>) => {
      if (query !== undefined) {
        setSearchQuery(query);
        inputRef.current?.focus();
      }
    },
    [inputRef, setSearchQuery]
  );

  const handleSetQueryAndSources = useCallback(
    ({
      query: incomingQuery,
      isBaseLLM: incomingIsBaseLLM,
      skillFilters: incomingSkillFilters,
      fileFilters: incomingFileFilters,
      answerFilters: incomingAnswerFilters,
    }: Partial<QAControllerEventArgs>) => {
      if (
        incomingQuery !== undefined &&
        incomingIsBaseLLM !== undefined &&
        incomingSkillFilters !== undefined &&
        incomingFileFilters !== undefined &&
        incomingAnswerFilters !== undefined
      ) {
        setSearchQuery(incomingQuery);
        setIsBaseLLM(incomingIsBaseLLM);
        setSkillFilters(incomingSkillFilters);
        setFileFilters(incomingFileFilters);
        setAnswerFilters(incomingAnswerFilters);
        inputRef.current?.focus();
      }
    },
    [
      setSearchQuery,
      setIsBaseLLM,
      setSkillFilters,
      setFileFilters,
      setAnswerFilters,
      inputRef,
    ]
  );

  useEffect(() => {
    qaController.listenEvent('setQuery', handleSetQuery);
    qaController.listenEvent('setQueryAndSources', handleSetQueryAndSources);
    qaController.listenEvent('saveWorkflow', handleSaveWorkflowTemplate);

    return () => {
      qaController.off('setQuery', handleSetQuery);
      qaController.off('setQueryAndSources', handleSetQueryAndSources);
      qaController.off('saveWorkflow', handleSaveWorkflowTemplate);
    };
  }, [
    qaController,
    handleSetQuery,
    handleSetQueryAndSources,
    handleSaveWorkflowTemplate,
  ]);

  useEffect(() => {
    setDynamicPlaceholder('');
    const words = placeholderText.split(' ');
    let currentText = '';
    let index = 0;
    let intervalTime = 90;

    const addWords = () => {
      if (index < words.length) {
        currentText += words[index];
        index++;

        currentText += ' ';
        setDynamicPlaceholder(currentText);
        intervalTime -= 10;
        setTimeout(addWords, intervalTime);
      }
    };

    const timeoutId = setTimeout(addWords, intervalTime);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [placeholderText, setDynamicPlaceholder]);

  useEffect(() => {
    if (queryParams[QueryParams.Search] && !isLoaded.current) {
      sendQuery(queryParams[QueryParams.Search]);

      window.history.replaceState({}, document.title, window.location.pathname);
      isLoaded.current = true;
    }
  }, [queryParams, sendQuery]);

  return (
    <div
      className={`${className} qaTextInputAppcuesClass flex flex-col gap-4 w-full px-1 pb-3 max-w-[750px] min-h-[90px] border border-solid border-gray-30 rounded-lg shadow-flat`}
    >
      <div className="flex px-3 pt-3">
        <QAMentionsInput
          autofocus={autofocus}
          bots={bots}
          dynamicPlaceholder={dynamicPlaceholder}
          handleTextInputChange={handleTextInputChange}
          inputRef={inputRef}
          mentions={mentions}
          onKeyDown={onKeyDown}
          searchQuery={searchQuery}
          setMentions={setMentions}
          showPopupFromBottom={showPopupFromBottom}
        />
      </div>
      <div className="flex justify-between items-center px-3">
        <div className="flex items-center gap-2">
          {!hideNewTopicButton && (
            <div className="flex">
              <UITooltip
                title={isNewTopic ? 'Resume previous topic' : 'Start new topic'}
              >
                <div
                  className="hover:bg-cloud-10 p-2 rounded-md cursor-pointer"
                  onClick={handleNewTopicButton}
                >
                  {isNewTopic ? (
                    <ReplayOutlinedIcon
                      sx={{ display: 'block', fontSize: 16 }}
                    />
                  ) : (
                    <MapsUgcOutlinedIcon
                      sx={{ display: 'block', fontSize: 16 }}
                    />
                  )}
                </div>
              </UITooltip>
            </div>
          )}
          <SourcesFilterSwitch
            allAnswers={allAnswers}
            allFiles={allFiles}
            allSkills={allSkills}
            allSkillsUserApps={allSkillsUserApps}
            answerFilters={answerFilters}
            bot={bots.find((b) => b.id === mentions)}
            botsEnabledApps={botsEnabledApps}
            botsEnabledUserApps={botsEnabledUserApps}
            fileFilters={fileFilters}
            isBaseLLM={isBaseLLM}
            setAnswerFilters={setAnswerFilters}
            setFileFilters={setFileFilters}
            setIsBaseLLM={setIsBaseLLM}
            setSkillFilters={setSkillFilters}
            showPopupFromBottom={showPopupFromBottom}
            skillFilters={skillFilters}
          />
          {bots.length > 0 && (
            <BotsSelectButtonDashAi
              bots={bots}
              disabled={!!mentions}
              setSelectedBot={(bot) => {
                setSearchQuery(
                  (prev) => `${prev} @[${bot.bot_name}](${bot.id}) `
                );

                setMentions(bot.id);
                trackEvent(AnalyticsEvent.MentionedBot, { bot_id: bot.id });
              }}
            />
          )}
          {((isBaseLLM && supportMultipleLlmProviders) || !isBaseLLM) && (
            <LLMSelectPopoverBtn
              bot={bots.find((b) => b.id === mentions)}
              selectedLlm={selectedLlm}
              setOpenPaywallModal={setOpenPaywallModal}
              setSelectedLlm={setSelectedLlm}
            />
          )}
          {!continueConversationId && (
            <UITooltip title="View workflows">
              <div
                className="flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border"
                onClick={handleViewTemplatesButton}
              >
                <UIIcon name="bolt" style={{ display: 'block' }} />
              </div>
            </UITooltip>
          )}
          {searchQuery.length > MAX_QUERY_LENGTH && !isBaseLLM && (
            <UITooltip
              title={`Dash AI works best when your query is limited to ${MAX_QUERY_LENGTH} characters.`}
            >
              <div className="hidden sm:block text-sm text-gray-50">
                <span className="text-mahogany-20">{searchQuery.length}</span> /{' '}
                {MAX_QUERY_LENGTH} chars
              </div>
            </UITooltip>
          )}
        </div>
        <div>
          {currentStage !== QAStages.WAIT_USER_INPUT && stopGenerationFlag ? (
            hideStopAnswerGenerationButton ? null : (
              <UIIconButton
                name="stop-circle"
                onClick={handleStopGeneration}
                size={24}
                tooltip="Stop generating answer"
                type="ui"
              />
            )
          ) : (
            <UIIconButton
              name="send"
              onClick={() => {
                sendQuery(searchQuery);
              }}
              type="ui"
            />
          )}
        </div>
      </div>
    </div>
  );
};
