import { LinearProgress } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import './QAMessageView.scss';
import { ctorMap } from '../../apps';
import { useAppDefinitions } from '../../apps/definition';
import dashworksLogo from '../../assets/images/dashworks_logo.svg';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import {
  MessageType,
  QAMessage,
  SkillRetrieved,
  StaticAnswerType,
} from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useFlag, useGlobalState } from '../../scripts/hooks';
import { BotsInfoHover } from '../bots/BotsInfoHover';
import { ExpandableList } from '../controls/ui/ExpandableList/ExpandableList';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { AnswerIcon } from '../controls/ui/icons/icons';
import { QaLoadingSkeletons } from '../general/Skeletons/QASkeleton';
import { MarkdownParser } from '../text/MarkdownParser';
import { QAActionRow } from './QAActionRow';
import { DebugLogs } from './QADebugLogs';
import { QAOnboardingMessage } from './onboarding/QAOnboardingMessage';
import { InlineLink } from './references/QAInlineReference';
import { QAReferencesList } from './references/QAReferencesList';
import { NavigationalItem } from './references/items/NavigationalItem';

interface QAMessageViewProps {
  qaMessage: QAMessage | undefined;
  senderDisplayName?: string;
  senderPhotoUrl?: string;
  refRelatedCollapsed?: boolean;
  isLoading?: boolean;
  isAnswerStreaming?: boolean;
  sharableConversation?: QAMessage[];
  showAllSources?: boolean;
  bots: Bot[];
}

const getInitials = (displayName: string | undefined) => {
  if (!displayName) {
    return '';
  }

  const userName = displayName.trim();
  const nameTokens = userName.split(/\s+/);
  const nameInitials = nameTokens.map((word) => word[0]?.toUpperCase());
  return nameInitials.join('');
};

// eslint-disable-next-line max-lines-per-function
export const QAMessageView: FC<QAMessageViewProps> = ({
  qaMessage,
  refRelatedCollapsed = false,
  senderDisplayName,
  senderPhotoUrl,
  isLoading = false,
  isAnswerStreaming = false,
  sharableConversation,
  showAllSources = false,
  bots,
}) => {
  const { userDisplayName, userPhotoUrl } = useGlobalState(
    (state) => state.meta
  );

  const displayPhotoUrl = senderDisplayName ? senderPhotoUrl : userPhotoUrl;
  const displayInitials = useMemo(() => {
    return senderDisplayName
      ? getInitials(senderDisplayName)
      : getInitials(userDisplayName);
  }, [senderDisplayName, userDisplayName]);

  const userPhotoComponent = useMemo(() => {
    if (displayPhotoUrl) {
      return <img className="messageUserPhoto avatar" src={displayPhotoUrl} />;
    }

    if (displayInitials) {
      return <div className="userInitials">{displayInitials}</div>;
    }

    return <UIIcon name="person" size={32} />;
  }, [displayInitials, displayPhotoUrl]);

  const assistantPhotoComponent = useMemo(() => {
    if (qaMessage?.bot_id) {
      const bot = bots.find((b) => b.id === qaMessage.bot_id);
      const botIconLarge = (
        <div className="text-[32px] leading-8">{bot?.icon ?? '🤖'}</div>
      );

      if (bot) {
        return <BotsInfoHover bot={bot} customTriggerIcon={botIconLarge} />;
      }

      return (
        <UITooltip title="This bot has been deleted.">{botIconLarge}</UITooltip>
      );
    }

    return <img className="messageUserPhoto" src={dashworksLogo} />;
  }, [qaMessage?.bot_id, bots]);

  const userMessageComponent = useMemo(() => {
    if (qaMessage?.bot_id) {
      const bot = bots.find((b) => b.id === qaMessage.bot_id);

      if (bot) {
        const botPattern = `@${bot.bot_name}`;
        const botRegex = new RegExp(`(${botPattern})`, 'g');
        const parts = qaMessage.messageText.split(botRegex);

        return (
          <div>
            {parts.map((part, index) =>
              part === botPattern ? (
                <span
                  className="bg-[#C9FFFF] p-1 pt-[1px] rounded-md"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`part_${index}`}
                >
                  {part}
                </span>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <MarkdownParser key={`part_${index}`} text={part} />
              )
            )}
          </div>
        );
      }
    }

    return <MarkdownParser text={qaMessage?.messageText ?? ''} />;
  }, [bots, qaMessage?.bot_id, qaMessage?.messageText]);

  const relativeTimeString = moment(qaMessage?.tsSentAt).fromNow();
  const absoluteTimeString = moment(qaMessage?.tsSentAt).toLocaleString();

  const showRelatedSearches = !useFlag('hideRelatedSearches');

  const isNavigationalQuery =
    qaMessage?.messageType === MessageType.NAVIGATION_ANSWER;

  const isOnboardingMessage = useMemo(
    () =>
      // eslint-disable-next-line @cspell/spellchecker
      qaMessage?.extraData?.staticAnswerType ===
        StaticAnswerType.ONBOARDING_INITAL ||
      qaMessage?.extraData?.staticAnswerType ===
        StaticAnswerType.ONBOARDING_ASK_QUESTION,
    [qaMessage]
  );

  const handleCopy = useCallback(() => {
    const message_id = qaMessage?.message_id;
    const row_id = qaMessage?.row_id;
    if (qaMessage?.sender === 'ASSISTANT') {
      trackEvent(AnalyticsEvent.QAMessageResponseUserCopiedText, {
        message_id,
        row_id,
      });
    } else {
      trackEvent(AnalyticsEvent.QAMessageQueryUserCopiedText, {
        message_id,
        row_id,
      });
    }
  }, [qaMessage?.sender, qaMessage?.message_id, qaMessage?.row_id]);

  if (!qaMessage) {
    return null;
  }

  return (
    <div
      className={classNames(
        'qaMessageView sm:max-w-3xl sm:mx-auto max-w-full mx-2 break-words',
        {
          botMessage: qaMessage.sender === 'ASSISTANT',
          'userMessage neumorphic-background border border-gray-20 border-solid rounded-lg':
            qaMessage.sender !== 'ASSISTANT',
        }
      )}
      data-ts-sent-at={qaMessage.tsSentAt}
    >
      {qaMessage.isPreFinalGeneration && (
        <div className="centeredUnit px-1">
          <LinearProgress
            className="linearProgress"
            sx={{ height: '1px', bottom: '1.5px' }}
            value={qaMessage.progressBar}
            variant="determinate"
          />
        </div>
      )}

      {isOnboardingMessage ? (
        <QAOnboardingMessage
          sharable={
            sharableConversation !== undefined &&
            sharableConversation.length > 0
          }
          type={qaMessage.extraData?.staticAnswerType}
        />
      ) : (
        <div className="qaMessage relative sm:max-w-3xl sm:mx-auto max-w-full mx-2 p-4">
          <div className="qaAuthorIcon">
            {qaMessage.sender === 'ASSISTANT'
              ? assistantPhotoComponent
              : userPhotoComponent}
          </div>
          <div className="qaMessageContentToolsRelated">
            <div className="qaMessageContentTools">
              <div
                className={classNames('qaMessageContent', {
                  loadingText: isLoading && !isAnswerStreaming,
                  streaming: isAnswerStreaming,
                })}
                onCopy={handleCopy}
              >
                {qaMessage.skillRetrieved && (
                  <SkillRetrievedLoadingComponent
                    appName={qaMessage.skillRetrieved.appName}
                    numDocs={qaMessage.skillRetrieved.numDocs}
                  />
                )}

                {qaMessage.sender === 'ASSISTANT' ? (
                  <MarkdownParser
                    // eslint-disable-next-line react/no-unstable-nested-components
                    customAnchorElement={(props) => (
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      <InlineLink props={props} qaMessage={qaMessage} />
                    )}
                    text={qaMessage.messageText}
                  />
                ) : (
                  userMessageComponent
                )}
              </div>
              <div
                className={classNames('qaMessageTools', {
                  top: qaMessage.sender === 'ASSISTANT',
                  center: qaMessage.sender === 'USER',
                })}
              >
                {qaMessage.sender === 'USER' && (
                  <div className="timeDisplay" title={absoluteTimeString}>
                    {relativeTimeString}
                  </div>
                )}
              </div>
            </div>

            {qaMessage.isPreFinalGeneration && <QaLoadingSkeletons />}

            {qaMessage.sender === 'ASSISTANT' && (
              <div className="referencesRelated">
                {isNavigationalQuery ? (
                  <ExpandableList
                    ItemComponent={NavigationalItem}
                    alwaysExpanded
                    className="referenceList"
                    defaultCollapsed={refRelatedCollapsed}
                    items={qaMessage.references}
                    maxItems={30}
                    title=""
                  />
                ) : (
                  <QAReferencesList
                    allReferencesSummary={qaMessage.allReferencesSummary}
                    answerId={qaMessage.row_id}
                    citedReferences={qaMessage.references}
                    showAllSources={showAllSources}
                    topicMessages={sharableConversation}
                  />
                )}

                {!isLoading && !isAnswerStreaming && (
                  <QAActionRow
                    qaMessage={qaMessage}
                    sharableConversation={sharableConversation}
                  />
                )}
                {showRelatedSearches && (
                  <ExpandableList
                    ItemComponent={RelatedSearchesItem}
                    className="relatedItemsList"
                    defaultCollapsed={refRelatedCollapsed}
                    items={qaMessage.relatedSearches}
                    title="Related"
                  />
                )}
                <ExpandableList
                  ItemComponent={DebugLogs}
                  className="relatedItemsList"
                  defaultCollapsed
                  items={qaMessage.debugLogs?.stages ?? []}
                  maxItems={100}
                  title="Debug"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const RelatedSearchesItem: FC<{ item: string; index: number }> = ({
  item: relatedSearch,
  index,
}) => {
  const qaController = useQAController();

  const onRelatedSearchClick = useCallback(() => {
    trackEvent(
      AnalyticsEvent.QAMessageRelatedSearchClicked,
      {
        position: index + 1,
      },
      {
        relatedSearch,
      }
    );

    qaController.triggerEvent('setQuery', { query: relatedSearch });
  }, [qaController, relatedSearch, index]);

  return (
    <li
      className={classNames('relatedSearchItem')}
      key={relatedSearch}
      onClick={onRelatedSearchClick}
    >
      {relatedSearch}
      <UIIcon name="keyboard-right" />
    </li>
  );
};

const SkillRetrievedLoadingComponent: FC<SkillRetrieved> = ({ appName }) => {
  const definitions = useAppDefinitions();

  let icon = null;
  let displayName = '';
  switch (appName) {
    case 'verified_answers': {
      displayName = 'Verified Answers';
      icon = <AnswerIcon size={20} />;

      break;
    }

    case 'files': {
      displayName = 'Files';
      icon = <UIIcon name="doc" />;

      break;
    }

    case 'web': {
      displayName = 'Web';
      icon = <UIIcon name="globe" />;

      break;
    }

    default: {
      const AppConstructor = ctorMap.get(appName);
      const definition = definitions[appName];

      if (AppConstructor && definition) {
        const app = new AppConstructor(definition);
        icon = <UIIcon name={app.definition.shortname} type="apps" />;
        ({ displayName } = definition);
      }
    }
  }

  return (
    <div className="skillRetrievedLoading">
      <div>Found results in</div>
      {icon && <div>{icon}</div>}
      <div>{displayName}...</div>
    </div>
  );
};
