import './SupportChatBotBubble.scss';
import React, { FC, useEffect } from 'react';
import SwiggyChatbotIcon from '../../../assets/images/swiggy_chatbot.png';
import { useFlag } from '../../../scripts/hooks';
import { UIIconButton } from '../ui/UIIconButton/UIIconButton';

declare const chatteron: { init: (arg0: { clientId: string }) => void };

export const SupportChatBotBubble: FC<{
  className?: string;
}> = () => {
  const customChatbot = useFlag('customChatbot');
  const homepageSupportEnabled = useFlag('homepageSupportEnabled');

  useEffect(() => {
    const leenaScript = document.getElementById('leena-script');

    if (!leenaScript && customChatbot) {
      const script = document.createElement('script', {});

      script.addEventListener('load', () => {
        chatteron.init({
          clientId: CHATTERON_CLIENT_ID,
        });
      });

      script.src = 'https://web.leena.ai/scripts/sdk.js';
      script.id = 'leena-script';
      script.async = true;
      document.body.append(script);

      // HACK: This is to make sure a 'load' event is fired after the script is loaded.
      const interval = setInterval(() => {
        const elements = document.getElementsByClassName('chatBubble');
        if (elements.length > 0) {
          dispatchEvent(new Event('load'));
          clearInterval(interval);
        }

        const botImageElements = document.getElementsByClassName('botImage');
        if (botImageElements.length > 0 && botImageElements[0]) {
          const image = botImageElements[0] as HTMLImageElement;
          image.src = SwiggyChatbotIcon;
        }
      }, 500);
    } else if (leenaScript && !customChatbot) {
      leenaScript.remove();
      location.reload();
    }
  }, [customChatbot]);

  if (customChatbot || !homepageSupportEnabled) {
    return null;
  }

  return (
    <div className="fixed bottom-5 right-5">
      <UIIconButton
        className="outline-none p-2 bg-white border border-solid border-gray-30 rounded-[50%]"
        name="question"
        onClick={() => {
          // eslint-disable-next-line new-cap
          Pylon('show');
        }}
        size={32}
        tooltip="Help"
      />
    </div>
  );
};
